import produce from "immer";
import { FETCH_PROJECTS, SET_LANGUAGE, ADD_PROJECT, UPDATE_PROJECT, SET_PROJECT_ID, DELETE_PROJECT, SET_PROJECTS_OF_TYPE, FETCH_PROJECTS_BY_TYPE } from "./types";

const initialState = {
    projects: [],
    projectsOfType: [],
    projectsSortByType:[],
    projectId: "",
    locale: 'he'
};

export const projectReducer = produce((initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case FETCH_PROJECTS:
            console.log('FETCH_PROJECTS');

            initialState.projects = payload;
            return initialState;
        case FETCH_PROJECTS_BY_TYPE:
            console.log('FETCH_PROJECTS_BY_TYPE');

            initialState.projectsSortByType = payload;
            return initialState;
        case SET_PROJECTS_OF_TYPE:
            console.log('SET_PROJECTS_OF_TYPE');
            
            initialState.projectsOfType = payload;
            return initialState;
        case SET_LANGUAGE:
            initialState.locale = payload;
            return initialState;
        case DELETE_PROJECT:
            initialState.projectsSortByType = payload.projectsByType;
            initialState.projectsOfType = payload.projectsOfType;
            return initialState;
        case SET_PROJECT_ID:
            sessionStorage.setItem('currentProjectId', JSON.stringify(payload));
            initialState.projectId = payload;
            return initialState;
        case ADD_PROJECT:
            sessionStorage.setItem('currentProjectId', JSON.stringify(payload.id));
            initialState.projects = payload.data;
            return initialState;
        case UPDATE_PROJECT:
            const projectIndex = initialState.projectsSortByType[payload.type].findIndex(project => project._id === payload._id);
            if (projectIndex !== -1) {
                initialState.projectsSortByType[payload.type][projectIndex] = { ...initialState.projectsSortByType[payload.type][projectIndex], ...payload };
            }
            break;
        default:
            return initialState;
    }
}, initialState)