import { useDispatch } from 'react-redux';
import { fetchReportByProjectId, updateReportPdf } from "../../../report/store/action";
import Spinner from '../../../../utils/spinner/spinner';
import { useState } from 'react';

export default function CreateFullPdf({ scanObject }) {
    const dispatch = useDispatch();
    const [disabledPage, setDisabledPage] = useState(false);

    const createPDF = async () => {
        setDisabledPage(true)
        let scansIds = [];
        scanObject.scansList.map(scan => {
            scansIds.push(scan.scanId)
        })
        console.log('scansIds', scansIds);

        let reportId;
        if (scanObject?.pdfLink) {
            const match = scanObject?.pdfLink?.match(/report([a-f0-9]+)\.application\/pdf/) || '';
            if (match && match[1]) {
                const objectId = match[1];
                reportId = objectId;
                console.log("Extracted Object ID:", objectId);
            } else {
                console.log("Object ID not found in the URL");
            }
        }
        else {
            const projectId = scanObject.scansList[0].relatedProject;
            const res = await dispatch(fetchReportByProjectId({ userId: scanObject.userId, projectId: projectId }))
            console.log('res', res);
            reportId = res._id

        }
        console.log('reportId', reportId);

        const obj = {
            reportId: reportId,
            userId: scanObject.userId,
            scansIdsToPdf: scansIds,
            locale: 'he',
            isAddBrokenLetter: true,
            isFullPdf: true
        }
        console.log('obj', obj);
        const res = await dispatch(updateReportPdf(obj))
        console.log('res', res);

        setDisabledPage(false)
        window.location.reload();
    }

    return (
        <div>
            <div onClick={createPDF}>
                הפק דוח מלא
            </div>

            {
                disabledPage == true &&
                <Spinner value={100}></Spinner>
            }
        </div>
    )
}