import './App.scss';
import React, { useEffect } from 'react'
import { BrowserRouter as Router } from 'react-router-dom';
import Layout from './pages/layout/layout';
import { Provider } from 'react-redux';
import store from '../src/stores/store'
import { smallScreen } from '../src/utils/constants';
import LayoutOnePage from './pages/layout-one-page/layout-one-page';
import LayoutDashboard from './pages/admin/layout-dashboard/layout-dashboard';


function App() {

  const isSmallScreen = smallScreen();
  const isFirstTime = localStorage.getItem('isFirstTime') === null ? true : JSON.parse(localStorage.getItem('isFirstTime'));
  const isDashboard = localStorage.getItem('isDashboard') === null ? false : JSON.parse(localStorage.getItem('isDashboard'));

  return (
    <div className={!isSmallScreen ? 'App' : ''} dir='ltr'>
      <Provider store={store}>
        <Router>
          {(isFirstTime == true && window.location.pathname == '/') ?
            <LayoutOnePage></LayoutOnePage>
            :
            isDashboard ? (<LayoutDashboard></LayoutDashboard>)
              : (<Layout></Layout>)
          }
        </Router>
      </Provider>

    </div>
  );
}
export default App;


