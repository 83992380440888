import React, { useState } from "react"
import Project from "../project/project";
import Spinner from "../../../utils/spinner/spinner";

export default function ProjectList({ projects }) {

    const [disabled, setDisabled] = useState(false);
    
    const disabledPage = (value) => {
        setDisabled(value)
    }

    return <>
        <div >
            {Array.isArray(projects) && projects.map((project) => (
                <Project key={project._id} proj={project} disabledPage={disabledPage} />
            ))}
            {
                disabled &&
                <Spinner value={10}></Spinner>
            }
        </div>
    </>
};
