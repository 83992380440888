export const CREATE_SCAN = 'CREATE_SCAN'
export const FETCH_SCANS = 'FETCH_SCANS'
export const FETCH_ALL_SCANS = 'FETCH_ALL_SCANS'
export const FETCH_SCANS_OF_FOLDER = 'FETCH_SCANS_OF_FOLDER'
export const SET_CURRENT_SCAN = 'SET_CURRENT_SCAN'
export const SET_ACTIVE_IMG_INDEX = 'SET_ACTIVE_IMG_INDEX'
export const SCANS_Of_CURRENT_FOLDER='SCANS_Of_CURRENT_FOLDER'
export const SET_IMAGES='SET_IMAGES'
export const SET_SEARCH_QUERY='SET_SEARCH_QUERY'
export const SCAN_IDS_TO_UPLOAD='SCAN_IDS_TO_UPLOAD'
export const DELETE_SCAN='DELETE_SCAN'
export const UPDATE_SCAN ='UPDATE_SCAN'



