import * as XLSX from 'xlsx';

export const exportToExcel = (data, fileName) => {
    // Step 1: Create a new workbook and worksheet
    const worksheet = XLSX.utils.json_to_sheet(data); // Convert JSON data to sheet
    worksheet['!sheetPr'] = { rtl: true };

    // Set column widths
    worksheet['!cols'] = [
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
    ];

    const workbook = XLSX.utils.book_new(); // Create a new workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1'); // Add sheet to workbook

    // Step 2: Create a file and trigger download
    XLSX.writeFile(workbook, `${fileName}.xlsx`);
};