import Modal from 'react-bootstrap/Modal';
import { BASE_URL, getOperatingSystem, smallScreen, useLanguage } from '../constants';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import ReactGA from 'react-ga4';
import { useNavigate } from 'react-router-dom';
import MedianBridge from "median-js-bridge";


console.log('MedianBridge', MedianBridge);
console.log('MedianBridge.downloads', MedianBridge.downloads);
export default function ViewPdf({ isShowPdf, setIsShowPdf, reportId, projectName }) {

    const [linkCopied, setLinkCopied] = useState(false)
    const [downloadClick, setDownloadClick] = useState(false)
    const language = useSelector((store) => store.userReducer.language);
    const [currentLanguage, setCurrentLanguage] = useState(language);
    const [isDouwload, setIsDouwload] = useState('');
    const t = useLanguage();
    const isSmallScreen = smallScreen();
    const currentUser = useSelector((store) => store.userReducer.currentUser);
    const navigate = useNavigate();
    useEffect(() => {
        setCurrentLanguage(language)
    }, [language])

    const downloadPDFfromURL = async (pdfUrl, fileName = 'download.pdf') => {
        console.log('downloadPDFfromURL');
        try {
            if (isSmallScreen) {
                if (getOperatingSystem() == 'iphone') {
                    window.open(pdfUrl, '_blank')
                }
                else
                    setIsDouwload(isShowPdf)
            }
            else {
                const response = await fetch(pdfUrl);
                console.log(response);
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = fileName;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
            }

        } catch (error) {
            console.error('Error downloading PDF:', error);
        }
    };


    return (
        <div>
            <Modal
                show={isShowPdf}
                onHide={() => setIsShowPdf('')}
                centered
                className={`modal ${isSmallScreen ? '' : `right-margin ${currentLanguage == 'en-US' ? 'ltr' : ''}`}`}
            >
                <Modal.Header closeButton>
                </Modal.Header>

                <Modal.Body className='pdf-modal' >
                    <Modal.Title>{t.report.pdfReady}</Modal.Title>
                    <div className={`pdf-link-wrapper ${isSmallScreen ? 'mobile' : ''}`}>
                        <button className={`open-pdf-link copy ${downloadClick ? 'disabled' : ''}`} onClick={downloadClick ? undefined : () => { downloadPDFfromURL(isShowPdf); }} >{downloadClick == true ? t.report.downloadClicked : t.report.download}</button>
                    </div>
                </Modal.Body>
            </Modal>
            {isDouwload && <iframe src={isShowPdf} width="100%" height="600px">
                Your browser does not support iframes.
            </iframe>}

        </div>
    )
}