import Modal from 'react-bootstrap/Modal';
import { smallScreen, useLanguage } from '../constants';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import './scan-slider.scss'

export default function ScanSlider({ images, scanType }) {
    const isSmallScreen = smallScreen();
    const language = useSelector((store) => store.userReducer.language);
    const [currentLanguage, setCurrentLanguage] = useState(language);
    const [index, setIndex] = useState(0);
    const t = useLanguage();
    useEffect(() => {
        setCurrentLanguage(language)
    }, [language])

    useEffect(() => {
        const intervalId = setInterval(() => {
            setIndex(prevIndex => (prevIndex < images.length - 1 ? prevIndex + 1 : 0));
        }, 2500); 

        return () => clearInterval(intervalId);
    }, [images.length]);

    return (<>
        <Modal
            show={true}
            centered
            className={`modal ${isSmallScreen ? '' : `right-margin ${currentLanguage == 'en-US' ? 'ltr' : ''}`}`}
        >
            <Modal.Body className='pdf-modal' >

                <div className={`scanning ${isSmallScreen ? 'mobile' : ""}`}>{`${t.uploadScans.scanning}${scanType == 'mezuza' ? images.length > 1 ? t.scanTypes.mezuza : t.scanTypesSingle.mezuza : t.scanTypes[scanType]} ${t.uploadScans.yours}`}</div>
                <div className={`slider-container ${scanType == 'mezuza' ? '' : 'tefillin'}`}>
                    <img
                        className={`slider-image ${scanType == 'mezuza' ? '' : 'tefillin'}`}
                        src={images[index]?.url}
                        style={{ transform: `rotate(${images[index]?.rotation}deg)` }}
                    ></img>
                    <div className="slider-bar-shadow"></div>
                </div>

            </Modal.Body>
        </Modal>

    </>)
}