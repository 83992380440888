import Header from '../header/header'
import './contact-style.scss'
import React, { useEffect, useRef, useState } from 'react';
import emailjs from 'emailjs-com';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentUserById } from '../user/store/action';
import copy from '../../utils/assets/copy.svg'
import confirmedCopy from '../../utils/assets/confirmed-copy.svg'
import { getEmail, getPhone, getPhoneNumber, smallScreen, useLanguage } from '../../utils/constants';
import Modal from "react-bootstrap/Modal";

export default function Contact() {
  const currentUserId = JSON.parse(localStorage.getItem('currentUser'))?.id;
  const currentUser = useSelector((store) => store.userReducer.currentUser);
  const language = useSelector((store) => store.userReducer.language);
  const [currentLanguage, setCurrentLanguage] = useState(language);
  const isCurrentUserEffect = useRef(true)
  const t = useLanguage();
  const isSmallScreen = smallScreen();
  const dispatch = useDispatch();
  const [message, setMessage] = useState('');
  const [header, setHeader] = useState('');
  const [isFormClick, setIsFormClick] = useState(false);
  const [isCopyPhone, setIsCopyPhone] = useState(false);
  const [isCopyEmail, setIsCopyEmail] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    setCurrentLanguage(language)
  }, [language])

  useEffect(() => {
    if (currentUserId && !currentUser && isCurrentUserEffect.current) {
      dispatch(getCurrentUserById(currentUserId));
      isCurrentUserEffect.current = false;
    }
  }, [currentUserId])

  const sendContact = (e) => {
    e.preventDefault();

    setIsFormClick(true)
    const form = document.getElementById('contact-form');

    emailjs.sendForm('service_ztwcnnf', 'template_qcwrsza', form, 'RG7No6OmeqiYqalp_')
      .then(async (result) => {
        setHeader('');
        setMessage('');
        setIsModalVisible(true);
        console.log('Email sent successfully', result.text);
      }, (error) => {
        console.log('Error sending email', error.text);
      });

  }


  return (
    <div>
      <div className={`${isSmallScreen ? 'pr-0 pl-0' : (currentLanguage == 'en-US' ? 'pl-3 pr-8' : 'pl-8 pr-3')}`}>
        <Header page={'contact'} context={[t.contact.contact]}></Header>
      </div>

      <div className={isSmallScreen ? 'contact-wrapper-mobile' : `contact-wrapper ${currentLanguage == 'en-US' ? 'ltr' : ''}`}>

        {/* leave-contact */}
        <div className={`leave-contact ${isSmallScreen ? 'mobile' : 'col-8'} `}>
          <div>
            <div className='bold-text'>{t.contact.leaveContact}</div>

            <form id='contact-form' name="contact-form" className={isSmallScreen ? 'form-mobile' : ''} onSubmit={sendContact}>

              <div className='bold-text-small '>{t.contact.header}</div>
              <div>
                <input
                  type='text'
                  name='name'
                  className={`contact-description  ${isSmallScreen ? 'mobile' : ''}`}
                  placeholder={t.contact.contactDescription}
                  value={header}
                  autocomplete='off'
                  onChange={(e) => setHeader(e.target.value)}
                />
              </div>

              <div className='bold-text-small'>{t.contact.contactContent}</div>
              <div>
                <textarea
                  name='message'
                  className={`contact-description leave ${isSmallScreen ? 'mobile' : ''} `}
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
              </div>

              {/* Hidden fields to transfer to the form */}
              <textarea hidden="hidden" name="user_email" id='userEmail' value={currentUser?.email} ></textarea>
              <textarea hidden="hidden" name="userName" id='userName' value={currentUser?.customerName} ></textarea>

              <button type='submit'
                className={`send-contact ${isSmallScreen ? 'mobile' : ''} ${(!header || !message) ? 'disabled' : ''}`}
                disabled={!header || !message || isFormClick == true}
                >
                {t.contact.sendContact}
              </button>

            </form>

          </div>
        </div>

        {/* contact-details */}
        <div className={`contact-details ${isSmallScreen ? 'mobile' : 'col-5'}  `}>
          <div>
            <div className=' bold-text pb-4'>{t.contact.details}</div>
            <div className='pb-4'>
              <p className='bold-text'>{t.contact.phone}</p>
              <div className='d-flex' >
                <div className={`blue-text show-image-phone pt-2 pb-2 ${currentLanguage == 'en-US' ? 'pr-2' : 'pl-2'}`} type='button'
                  onClick={() => { navigator.clipboard.writeText(getPhoneNumber()); setIsCopyPhone(true) }}
                >{getPhone()}</div>
                <img src={isCopyPhone ? confirmedCopy : copy} className='hide-img'></img>
              </div>

            </div>
            <div className='pb-4'>
              <p className='bold-text'>{t.contact.email}</p>
              <div className='d-flex ' >
                <div className={`blue-text show-image-mail pt-2 pb-2 ${currentLanguage == 'en-US' ? 'pr-2' : 'pl-2'}`} type='button'
                  onClick={() => { navigator.clipboard.writeText(getEmail()); setIsCopyEmail(true) }}>{getEmail()}</div>
                <img src={isCopyEmail ? confirmedCopy : copy} className='hide-img'></img>
              </div>
            </div>
            <div>
              <p className='bold-text'>{t.contact.activityTime}</p>
              <p className='bold-text-small'>{t.contact.sundayThursday}</p>
              <p className='blue-text'>8:30-16:00</p>
              <p className='bold-text-small'>{t.contact.onFridayAndolHidayEves}</p>
              <p className='blue-text'>{t.contact.centerIsClosed}</p>
            </div>
          </div>
        </div>

        {/* success modal */}
        <Modal
          show={isModalVisible}
          onHide={() => setIsModalVisible(false)}
          centered
          className={`modal ${isSmallScreen ? "" : `right-margin ${currentLanguage == "en-US" ? "ltr" : ""}`}`}
        >
          <Modal.Header closeButton>
          </Modal.Header>
          <Modal.Body>
            <div className='blue-text text-center pt-4'>{t.contact.contactSendSuccessfuly}</div>
            <div className='text-center pt-4 pb-4'>{t.contact.weTreatContact}</div>
          </Modal.Body>
        </Modal>
      </div>

    </div>
  )
}
