import { useDispatch, useSelector } from "react-redux";
import { smallScreen, useLanguage } from "../../../utils/constants";
import Header from "../../header/header";
import { getCurrentUserById, showAllUserPopups, updateUserIsAddBrokenLetter } from "../store/action";
import { useEffect, useRef } from "react";
import './user-settings.scss'
export default function UserSettings() {
    const currentUserId = JSON.parse(localStorage.getItem('currentUser'))?.id;
    const currentUser = useSelector((store) => store.userReducer.currentUser);
    const isSmallScreen = smallScreen();
    const t = useLanguage();
    const dispatch = useDispatch()
    const isCurrentUserEffect = useRef(true)

    useEffect(() => {
        if (currentUserId && isCurrentUserEffect.current) {
            dispatch(getCurrentUserById(currentUserId));
            isCurrentUserEffect.current = false;
        }
    }, [currentUserId, currentUser])

    const addBrokenLetterError = async (event) => {
        const isChecked = event.target.checked;
        await dispatch(updateUserIsAddBrokenLetter({ userId: currentUserId, isAddBrokenLetter: isChecked }))
    }
    const showAllPopups = async (event) => {
        const isChecked = event.target.checked;
        await dispatch(showAllUserPopups({ userId: currentUserId, isShowPopups: !isChecked }))
    }

    return (
        <div >
            <Header page={'settings'} context={[t.navbar.settings]}></Header>
            <div className="user-settings-wrapper">
                <div>

                    <div className="row1 pt-4">
                        <input type="checkbox"
                            checked={currentUser?.isAddBrokenLetter || false}
                            onChange={(e) => addBrokenLetterError(e)}
                            style={{ width: '20px', height: '20px' }} // Adjust size as needed
                        ></input>
                        <div className={`add-broken-letter-text ${isSmallScreen ? 'mobile' : ''}`}>
                            הוספת התראה על ‘אות קטועה’ בדו״ח
                        </div>
                    </div>
                    <div className="row1 pt-4">
                        <input type="checkbox"
                            onChange={(e) => showAllPopups(e)}
                            style={{ width: '20px', height: '20px' }} // Adjust size as needed
                        ></input>
                        <div className={`add-broken-letter-text ${isSmallScreen ? 'mobile' : ''}`}>
                             הצג שוב את כל תיבות העזרה
                        </div>
                    </div>
                </div>


                <div className={`add-broken-letter-text ${isSmallScreen ? 'mobile' : ''}`}>
                    עוד הגדרות חשובות לנוחיותכם יתווספו בהמשך....
                </div>
            </div>

        </div>

    )
}