import React, { useRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { smallScreen } from '../constants';
import './show-image-style.scss'

const ImageCanvas = ({ width, height, coords }) => {
    const canvasRef = useRef(null);
    const imgContainerRef = useRef(null);
    const [dataUrl, setDataUrl] = useState('');
    const imageUrl = useSelector((store) => store.reportReducer.imageUrl);
    const coordinatesStore = useSelector((store) => store.reportReducer.coordinates);
    const coordinates = coords || coordinatesStore;
    const scanType = JSON.parse(sessionStorage.getItem('scanType'));
    const isSmallScreen = smallScreen();
    const [enlargCoordinates, setEnlargeCordinates] = useState({
        x: (coordinates.x - 15) > 0 ? (coordinates.x - 15) : 1,
        y: (coordinates.y - 15) > 0 ? (coordinates.y - 15) : 1,
        width: (coordinates.x + coordinates.width + 30) > width ? (width - coordinates.x) : (coordinates.width + 30),
        height: coordinates.height + 30
    })

    useEffect(() => {
        const image = new Image();
        image.crossOrigin = 'Anonymous';

        image.onload = function () {
            const canvas = canvasRef.current;
            if (canvas) {
                const ctx = canvas.getContext('2d');

                ctx.clearRect(0, 0, width, height);
                ctx.drawImage(image, 0, 0, width, height);
                ctx.strokeStyle = 'red';
                ctx.lineWidth = 5;
                ctx.strokeRect(enlargCoordinates.x, enlargCoordinates.y, enlargCoordinates.width, enlargCoordinates.height);

                const croppedUrl = canvas.toDataURL('image/jpeg');
                setDataUrl(croppedUrl);

                if (scanType === 'tefillin' && isSmallScreen) {
                    setTimeout(() => {
                        if (imgContainerRef.current) {
                            imgContainerRef.current.scrollLeft = enlargCoordinates.x - imgContainerRef.current.clientWidth;
                            console.log('imgContainerRef', imgContainerRef.current.scrollLeft);
                        }
                    }, 100);
                }
            };
        }

        image.src = imageUrl;
    }, [imageUrl, coordinates, width, height]);

    useEffect(() => {
        setEnlargeCordinates({
            x: (coordinates.x - 15) > 0 ? (coordinates.x - 15) : 1,
            y: (coordinates.y - 15) > 0 ? (coordinates.y - 15) : 1,
            width: (coordinates.x + coordinates.width + 30) > width ? (width - coordinates.x) : (coordinates.width + 30),
            height: coordinates.height + 30
        })
    }, [coordinates])

    return (
        <div
            ref={imgContainerRef}
            className='overflow-div'
            style={{
                overflowX: 'auto',
                overflowY: 'auto',
                maxWidth: '100%',
                maxHeight: '100%',
                whiteSpace: 'nowrap',
                position: 'relative'
            }}
        >
            <canvas ref={canvasRef} width={width} height={height} style={{ display: 'none' }} />
            {dataUrl && (
                <img
                    src={dataUrl}
                    alt="Combined Image"
                    style={scanType === 'tefillin' && isSmallScreen ? { height: '50vh' } : { maxWidth: '100%', height: 'auto' }}
                />
            )}
        </div>
    );
};

export default ImageCanvas;
