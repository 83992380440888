// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scan-message {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 0.5rem;
}

.scan-message-mobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding-bottom: 1rem;
}

.choose-all-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.8rem;
  margin-right: 2rem;
  padding-bottom: 1rem;
}
@media screen and (max-width: 720px) {
  .choose-all-wrapper {
    margin-right: 1.5rem;
  }
}

.choose-all-wrapper.report-choose-all {
  padding-bottom: unset;
  margin-right: unset;
}

.report-choose-all-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem 0 0;
}

.choose-all-wrapper.ltr {
  direction: ltr;
  margin-left: 2.5rem;
}
@media screen and (max-width: 720px) {
  .choose-all-wrapper.ltr {
    margin-left: 1.9rem;
  }
}

.choose-all {
  font-size: 1.25rem;
  font-weight: 650;
  display: flex;
}

.choose-all-checkbox {
  width: 1.3rem;
  height: 1.3rem;
  top: 0;
  right: 0;
  accent-color: #0E0D1F;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/pages/scans/scan-list/scan-list-style.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,mBAAA;AACJ;;AAEA;EACI,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,kBAAA;EACA,oBAAA;AACJ;;AAEA;EACI,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,WAAA;EACA,kBAAA;EACA,oBAAA;AACJ;AACI;EARJ;IASQ,oBAAA;EAEN;AACF;;AAEA;EACI,qBAAA;EACA,mBAAA;AACJ;;AAEA;EACI,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,wBAAA;AACJ;;AAEA;EACI,cAAA;EACA,mBAAA;AACJ;AACI;EAJJ;IAKQ,mBAAA;EAEN;AACF;;AACA;EACI,kBAAA;EACA,gBAAA;EACA,aAAA;AAEJ;;AACA;EACI,aAAA;EACA,cAAA;EACA,MAAA;EACA,QAAA;EACA,qBAAA;EACA,eAAA;AAEJ","sourcesContent":[".scan-message {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    margin-left: 0.5rem;\n}\n\n.scan-message-mobile {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    text-align: center;\n    padding-bottom: 1rem;\n}\n\n.choose-all-wrapper {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    gap: 0.8rem;\n    margin-right: 2rem;\n    padding-bottom: 1rem;\n\n    @media screen and (max-width:720px) {\n        margin-right: 1.5rem;\n    }\n\n}\n\n.choose-all-wrapper.report-choose-all {\n    padding-bottom: unset;\n    margin-right: unset;\n}\n\n.report-choose-all-container {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    padding: 0.5rem 1rem  0 0;\n}\n\n.choose-all-wrapper.ltr {\n    direction: ltr;\n    margin-left: 2.5rem;\n\n    @media screen and (max-width:720px) {\n        margin-left: 1.9rem;\n    }\n}\n\n.choose-all {\n    font-size: 1.25rem;\n    font-weight: 650;\n    display: flex;\n}\n\n.choose-all-checkbox {\n    width: 1.3rem;\n    height: 1.3rem;\n    top: 0;\n    right: 0;\n    accent-color: #0E0D1F;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
