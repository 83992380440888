// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-settings-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 60vh;
  padding: 1rem;
}

.add-broken-letter-text {
  font-size: 1.35rem;
  letter-spacing: -0.0375rem;
  font-weight: 300;
  line-height: normal;
  letter-spacing: -0.015rem;
}

.add-broken-letter-text.mobile {
  font-size: 1.15rem;
}

.pt-4 {
  padding-top: 4rem;
}`, "",{"version":3,"sources":["webpack://./src/pages/user/settings/user-settings.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,8BAAA;EACA,SAAA;EACA,aAAA;AACJ;;AAEA;EACI,kBAAA;EACA,0BAAA;EACA,gBAAA;EACA,mBAAA;EACA,yBAAA;AACJ;;AAEA;EACI,kBAAA;AACJ;;AAEA;EACI,iBAAA;AACJ","sourcesContent":[".user-settings-wrapper {\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    gap: 60vh;\n    padding: 1rem;\n}\n\n.add-broken-letter-text {\n    font-size: 1.35rem;\n    letter-spacing: -0.0375rem;\n    font-weight: 300;\n    line-height: normal;\n    letter-spacing: -0.015rem;\n}\n\n.add-broken-letter-text.mobile {\n    font-size: 1.15rem;\n}\n\n.pt-4 {\n    padding-top: 4rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
