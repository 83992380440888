import React, { useEffect, useState } from "react";
import PdfViewerComponent from "./PDFViewer";
import { useSelector } from "react-redux";

export default function ShowPdf() {
    // const pdfUrl = useSelector(store => store.reportReducer.pdfUrl);
    const pdfUrl = 'https://stamimages.blob.core.windows.net/reports-pdf/report67430887293a8dd8634050f5.application/pdf'
    const [pdfBlob, setPdfBlob] = useState(null);
    const [blobUrl, setBlobUrl] = useState(null);
    console.log('pdfBlob', pdfBlob);
    console.log('blobUrl', blobUrl);
    
    useEffect(() => {
        // Example: Fetch PDF as Blob
        fetch(pdfUrl)
            .then((response) => response.blob())
            .then((blob) => setPdfBlob(blob));
    }, []);

    useEffect(() => {
        if (pdfBlob) {
            const url = URL.createObjectURL(pdfBlob);
            setBlobUrl(url);

            // Clean up the object URL when the component unmounts
            return () => {
                URL.revokeObjectURL(url);
            };
        }
    }, [pdfBlob]);

    return (
        <div>
            iframe blobUrl
            <iframe src={blobUrl} width='100%' height='500px'></iframe>
            iframe url
            <iframe src={pdfUrl} width='100%' height='500px'></iframe>
            iframe local
            <iframe src={'pdf.pdf'} width='100%' height='500px'></iframe>
            {/* <div className="PDF-viewer">
                PDF-viewer pdfUrl
                <PdfViewerComponent document={pdfUrl} />
                PDF-viewer pdf.pdf
                <PdfViewerComponent document={'pdf.pdf'} />
            </div> */}
        </div>
    )
}
