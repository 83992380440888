
import axios from 'axios'
import { BASE_URL } from '../../../utils/constants'

const URL = `${BASE_URL}/api/user/`

export const create_user = (user) => {
      return axios.post(`${URL}create_user`, user).then((response) => response.data)
}

export const get_user_login = (user) => {
      return axios.post(`${URL}login`, user).then((response) => response.data)
}

export const get_all_users = () => {
      return axios.get(`${URL}get_all_users`).then((response) => response.data)
}

export const get_users_by_id = (usersId) => {
      return axios.post(`${URL}get_users_by_id`, usersId).then((response) => response.data)
}

export const get_users_by_role = (obj) => {
      return axios.post(`${URL}get_users_by_role`, obj).then((response) => response.data)
}

export const get_current_user_by_id = (userId) => {
      return axios.post(`${URL}get_current_user_by_id`, userId).then((response) => response.data).catch((error) => error)
}

export const getUserGoogelLogin = (t) => {
      return axios.post(`${URL}googel_login`, null, {
            headers: { "x-access-token": t },
      }).then((response) => response.data).catch((error) => error)
}

export const getLastPaymentSum = (userId) => {
      return axios.post(`${URL}last_payment`, userId).then((response) => response.data).catch((error) => error)
}

export const is_email_exist = (userEmail) => {
      return axios.post(`${URL}is_email_exist`, userEmail).then((response) => response.data).catch((error) => error)
}

export const logout = (userId) => {
      return axios.post(`${URL}logout`, userId).then((response) => response.data).catch((error) => error)
}

export const send_email = (obj) => {
      return axios.post(`${URL}send_email`, obj).then((response) => response.data).catch((error) => error)
}

export const update_user = (obj) => {
      return axios.post(`${URL}update_user`, obj).then((response) => response.data).catch((error) => error)
}

export const update_user_password = (obj) => {
      return axios.post(`${URL}update_user_password`, obj).then((response) => response.data).catch((error) => error)
}

export const update_user_scan_type = (obj) => {
      return axios.post(`${URL}update_user_scan_type`, obj).then((response) => response.data).catch((error) => error)
}
export const update_user_is_add_broken_letter = (obj) => {
      return axios.post(`${URL}update_user_is_add_broken_letter`, obj).then((response) => response.data).catch((error) => error)
}
export const update_user_popups = (obj) => {
      return axios.post(`${URL}update_user_popups`, obj).then((response) => response.data).catch((error) => error)
}

export const update_user_dont_show_again = (obj) => {
      return axios.post(`${URL}update_user_dont_show_again`, obj).then((response) => response.data).catch((error) => error)
}

export const updateUserBalance = (obj) => {
      try {
            console.log('obj in updateUserBalance', obj);
            return axios.post(`${URL}update_user_balance`, obj).then((response) => response.data).catch((error) => error)
      }
      catch (error) {
            console.log(error);
            return null;
      }
}

export const updateUserBalanceInProgress = (obj) => {
      try {
            console.log('obj in updateUserBalance', obj);
            return axios.post(`${URL}update_user_balance_in_progress`, obj).then((response) => response.data).catch((error) => error)
      }
      catch (error) {
            console.log(error);
            return null;
      }
}

export const updateUserLastPayment = (obj) => {
      console.log('obj in updateUserBalance', obj);
      return axios.post(`${URL}update_user_last_payment`, obj).then((response) => response.data).catch((error) => error)
}

export const updateUserLastPaymentAndBalance = (obj) => {
      console.log('obj in updateUserBalance', obj);
      return axios.post(`${URL}update_user_last_payment_and_balance`, obj).then((response) => response.data).catch((error) => error)
}

export const updateUserBalanceAndBalanceInProgress = (obj) => {
      return axios.post(`${URL}update_user_last_payment_and_balance`, obj).then((response) => response.data).catch((error) => error)
}

export const delete_user = (userId) => {
      return axios.post(`${URL}delete_user`, userId).then((response) => response.data).catch((error) => error)
}

export const compare_password = (obj) => {
      return axios.post(`${URL}compare_password`, obj).then((response) => response.data).catch((error) => error)
}