
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { setImages } from '../../pages/scans/store/action';
import { BASE_URL, smallScreen } from '../constants';
import './view-image-style.scss';

const ViewImage = ({ imageId, scanId, coords, originalImageWidth, originalImageHeight, scanTypeProps, showSmallImage }) => {
  // const [imageUrl, setImageUrl] = useState('');
  const [loading, setLoading] = useState(true);
  const isSmallScreen = smallScreen();
  const scanIdStore = useSelector((store) => store.reportReducer.imageId)
  const coordinatesStore = useSelector((store) => store.reportReducer.coordinates)
  const currentScanId = scanId || scanIdStore;
  const coordinates = coords || coordinatesStore;
  const threshold = originalImageWidth / 500;
  const tefillinThresholdHeight = originalImageHeight / threshold;
  const numberToDivideWidth = 500;
  const numberToDivideHeight = scanTypeProps == 'tefillin' ? tefillinThresholdHeight : 500;
  const thresholdMobile = originalImageWidth / 340;
  const tefillinThresholdHeightMobile = originalImageHeight / thresholdMobile;
  const numberToDivideWidthMobile = window.innerWidth * 0.95;
  const numberToDivideHeightMobile = scanTypeProps == 'tefillin' ? tefillinThresholdHeightMobile : 340;
  const [thresholdWidth, setThresholdWidth] = useState(-1);
  const [thresholdHeight, setThresholdHeight] = useState(-1);
  const scanType = JSON.parse(sessionStorage.getItem('scanType'));

  const imageUrl = `https://stamimages.blob.core.windows.net/scans-images/scan${currentScanId}.jpg`;

  const img = new Image();
  img.src = imageUrl;
  img.onload = function () {
    const width = this.width;
    const height = this.height;
    const widthThreshold = width / originalImageWidth;
    const heightThreshold = height / originalImageHeight;

    if (isSmallScreen) {
      setThresholdWidth(width / numberToDivideWidthMobile / widthThreshold);
      setThresholdHeight(height / numberToDivideHeightMobile / heightThreshold);
    }
    else {
      setThresholdWidth(width / numberToDivideWidth / widthThreshold);
      setThresholdHeight(height / numberToDivideHeight / heightThreshold);
    }
    setLoading(false);
  };


  const adjustedX = coordinates ? (coordinates.x / thresholdWidth) - (scanType == 'tefillin' ? 3 : 5) : 0;
  const adjustedY = coordinates ? (coordinates.y / thresholdWidth) - (scanType == 'tefillin' ? 3 : 5) : 0;
  const adjustedWidth = coordinates ? (coordinates.width / thresholdWidth) + (scanType == 'tefillin' ? 6 : 10) : 0;
  const adjustedHeight = coordinates ? (coordinates.height / thresholdHeight) + (scanType == 'tefillin' ? 6 : 10) : 0;

  const squareStyle = (coordinates !== null && Object.keys(coordinates).length !== 0)
    ? {
      position: 'absolute',
      border: '2px solid red',
      left: adjustedX + 'px',
      top: adjustedY + 'px',
      width: adjustedWidth + 'px',
      height: adjustedHeight + 'px',
    }
    : {};


  return (
    <div
      className={`overflow-div`}
      style={showSmallImage ? {} : {
        overflowX: 'auto',
        overflowY: 'auto',
        maxWidth: '100%',
        maxHeight: '100%',
        whiteSpace: 'nowrap',
        position: 'relative'
      }}
    >
      {loading ? <div className="loading-text"><div class="d-flex align-items-center jusify-content-center">
        <div class="spinner-border ms-auto" aria-hidden="true"></div></div></div>
        :
        <img src={imageUrl} alt='img'
          // style={scanType === 'tefillin' && isSmallScreen ? { height: '50vh' } : { maxWidth: '100%', height: 'auto' }}
          style={showSmallImage ?
            { width: `${isSmallScreen ? numberToDivideWidthMobile : numberToDivideWidth}px`, height: `auto` }
            :
            scanType === 'tefillin' && isSmallScreen ? { height: '50vh' } :
             { maxWidth: '100%', height: 'auto' }
          }
        />
        // <img src={imageUrl} alt='img' loading="eager" className='sapir2' />

      }
      {(coordinates !== null && Object.keys(coordinates).length !== 0) && <div style={squareStyle}></div>}
    </div>
  );
};

export default ViewImage;
