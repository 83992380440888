import produce from "immer";
import { CREATE_DOCUMENT, CREATE_TRANSACTION_PAYPAL, CREATE_TRANSACTION_WITH_TOKEN, GET_IFRAME, GET_PAYMENTS, GET_TOKEN, REFUND_TRANSACTION } from "./types";

const initialState = {
    document:null,
    payments:[]
};

export const paymentReducer = produce((initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_PAYMENTS:
            initialState.payments=payload;
            return initialState;
        case GET_IFRAME:
            return initialState;
        case GET_TOKEN:
            initialState.token = payload
            return initialState;
        case CREATE_TRANSACTION_WITH_TOKEN:
            initialState.transaction = payload
            return initialState;
            case CREATE_TRANSACTION_PAYPAL:
            initialState.transaction = payload
            return initialState;
        case REFUND_TRANSACTION:
            initialState.transaction = payload
            return initialState;
        case CREATE_DOCUMENT:
            initialState.document = payload
            return initialState;
        case REFUND_TRANSACTION:
            initialState.document = payload
            return initialState;

        default:
            return initialState;
    }
}, initialState)



