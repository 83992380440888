import { useEffect, useRef } from 'react';
import { BASE_URL } from '../constants';

export default function PdfViewerComponent(props) {
    const containerRef = useRef(null);

    useEffect(() => {
        const container = containerRef.current;
        let PSPDFKit, instance;

        (async function () {
            PSPDFKit = await import('pspdfkit');

            PSPDFKit.unload(container);

            instance = await PSPDFKit.load({
                container,
                document: props.document,
                baseUrl: `${window.location.protocol}//${window.location.host}/`,
            });
        })();

        return () => PSPDFKit && PSPDFKit.unload(container);
    }, []);

    return (
        <div
            className='bg-info'
            ref={containerRef}
            style={{ width: '100%', maxWidth: '100%', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        />
    );
}