import React, { useEffect } from "react"
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { smallScreen } from '../../../utils/constants';
import { setProjectsOfType } from "../store/action";
import ProjectList from "../project-list/project-list";
import NewScan from "../../scans/new-scan/new-scan";
import { useLanguage } from '../../../utils/constants';
import Header from "../../header/header";
import { useState } from "react";
import { getCurrentUserById } from "../../user/store/action";
import { useRef } from "react";
import './project-page-style.scss'
import Spinner from "../../../utils/spinner/spinner";

export default function ProjectPage() {
    const location = useLocation();
    const dispatch = useDispatch()
    const searchParams = new URLSearchParams(location.search);
    const typeFolder = searchParams.get('type');
    const isSmallScreen = smallScreen();
    const currentUserId = JSON.parse(localStorage.getItem('currentUser'))?.id;
    const currentUser = useSelector((store) => store.userReducer.currentUser);
    const projectsByTypeList = useSelector((store) => store.projectReducer.projectsOfType);
    const projectsSortByType = useSelector((store) => store.projectReducer.projectsSortByType);
    const t = useLanguage();
    const isCurrentUserEffect = useRef(true)
    const [isShowList, setIsShowList] = useState(true);
    const [activeIndex, setActiveIndex] = useState(0);
    const timerRef = useRef(null);

    useEffect(() => {
        dispatch(setProjectsOfType(projectsSortByType[typeFolder]));
    }, [projectsSortByType])

    useEffect(() => {
        setIsShowList(true);

        timerRef.current = setTimeout(() => {
            setIsShowList(false);
        }, 4000);

        return () => clearTimeout(timerRef.current);
    }, []);

    useEffect(() => {
        if (projectsByTypeList?.length > 0) {
            if (timerRef.current) {
                clearTimeout(timerRef.current);
                timerRef.current = null;
            }
            setIsShowList(false);
        }
    }, [projectsByTypeList]);

    useEffect(() => {
        if (currentUserId && !currentUser && isCurrentUserEffect.current) {
            isCurrentUserEffect.current = false;
            dispatch(getCurrentUserById(currentUserId));
        }
    }, [currentUserId])


    useEffect(() => {
        const interval = setInterval(() => {
            setActiveIndex(prevIndex => (prevIndex + 1) % 3);
        }, 500);

        return () => clearInterval(interval);
    }, []);

    return (
        <div className={`container`}>
            <div className={isSmallScreen ? 'row wrapper-mobile ' : "row wrapper"}>
                <div className="col">

                    <Header page={'folders'} context={[typeFolder ? `${t.scanTypes[typeFolder]}` : '']}></Header>

                    <div className={'mt-3'}>
                        <NewScan typeFolder={typeFolder}></NewScan>
                    </div>


                    <div className="scrollable-div folders">
                        {isShowList ?
                            <div className="slider">
                                <div className={`dot ${activeIndex === 0 ? 'active' : ''}`}></div>
                                <div className={`dot ${activeIndex === 1 ? 'active' : ''}`}></div>
                                <div className={`dot ${activeIndex === 2 ? 'active' : ''}`}></div>
                            </div>
                            :
                            projectsByTypeList?.length > 0 ?
                                <ProjectList projects={projectsByTypeList}></ProjectList>
                                :
                                <div className="text-center mt-2">{t.project.noFolders}</div>
                        }
                    </div>

                </div>
            </div>
          

        </div>
    )
}