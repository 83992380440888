export const GET_USER_LOGIN = 'GET_USER_LOGIN'
export const GET_USERS = 'GET_USERS'
export const GET_CURRENT_USER = 'GET_CURRENT_USER'
export const SET_CURRENT_USER = 'SET_CURRENT_USER'
export const UPDATE_TYPE = 'UPDATE_TYPE';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_DONT_SHOW_AGAIN = 'UPDATE_DONT_SHOW_AGAIN';
export const LOGOUT = 'LOGOUT';
export const SEND_EMAIL = 'SEND_EMAIL';
export const COMPARE_PASSWORD = 'COMPARE_PASSWORD';
export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';
export const GET_ALL_USERS = 'GET_ALL_USERS';
export const UPDATE_IS_SHOW_BROKEN_LETTER = 'UPDATE_IS_SHOW_BROKEN_LETTER';
