import { useForm } from 'react-hook-form'
import Header from "../../header/header";
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { dataURLtoBlob, emailPatternConst, passwordPatternConst, smallScreen, useLanguage } from '../../../utils/constants';
import hidePassword from '../../../utils/assets/hide-password.svg'
import showPasswordImg from '../../../utils/assets/show-password.svg'
import editProfile from '../../../utils/assets/edit-profile.svg'
import { useEffect } from 'react';
import { useRef } from 'react';
import { comparePassword, deleteUser, getCurrentUserById, updateUser } from '../store/action';
import './edit-profile-style.scss'
import Modal from 'react-bootstrap/Modal';

export default function EditProfile() {

    const currentUserId = JSON.parse(localStorage.getItem('currentUser'))?.id;
    const currentUser = useSelector((store) => store.userReducer.currentUser);
    const isComparePasswordValid = useSelector((store) => store.userReducer.comparePassword);
    const isSmallScreen = smallScreen();
    const language = useSelector((store) => store.userReducer.language);
    const [currentLanguage, setCurrentLanguage] = useState(language);
    const dispatch = useDispatch()
    const t = useLanguage();
    const passwordPattern = passwordPatternConst();
    const emailPattern = emailPatternConst();
    const [showPassword, setShowPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)
    const isCurrentUserEffect = useRef(true)
    const fileInputRef = useRef(null);
    const [img, setImg] = useState('');
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const {
        handleSubmit,
        register,
        watch,
        formState: { errors, isSubmitting },
    } = useForm()

    const navigate = useNavigate();

    useEffect(() => {
        setCurrentLanguage(language)
    }, [language])

    useEffect(() => {
        if (currentUserId && !currentUser && isCurrentUserEffect.current) {
            dispatch(getCurrentUserById(currentUserId));
            isCurrentUserEffect.current = false;
        }
    }, [currentUserId])

    useEffect(() => {
        if (watch('currentPassword')) {
            if (watch('currentPassword').length >= 8) {
                console.log('currentPassword', watch('currentPassword'));
                dispatch(comparePassword({ password: watch('currentPassword'), userPassword: currentUser.password }))
                console.log('isComparePasswordValid', isComparePasswordValid);
            }
        }
    }, [watch('currentPassword')])


    const changeImage = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        console.log('selectedFile', selectedFile);
        
        const reader = new FileReader();

        reader.onloadend = () => {
            const dataUrl = reader.result;
            console.log('dataUrl', dataUrl);
            
            setImg(dataUrl);
            //todo save the image in azure, 
            //and save 
        };

        if (selectedFile) {
            reader.readAsDataURL(selectedFile);
        }

    };


    function onSubmit(values) {
        console.log('onSubmit🙏🙏🙏🙏🙏', values);

        return new Promise(async (resolve) => {
            setTimeout(() => {
                console.log(JSON.stringify(values, null, 2))
                resolve(values)
            }, 3000)

            // Create FormData and append image data
            const formData = new FormData();
            formData.append('userId', currentUserId);
            if (img && dataURLtoBlob(img)){
                console.log('profile',dataURLtoBlob(img) );
                formData.append('profile', dataURLtoBlob(img));
            }
            // Append other form values to FormData
            Object.entries(values).forEach(([key, value]) => {
                formData.append(key, value);
            });

            const user = await dispatch(updateUser(formData))
            if (user)
                navigate("/dashboard");
        })
    }

    const deleteAcount = async () => {
        await dispatch(deleteUser(currentUserId))
    }

    return (
        <>
            <div className={isSmallScreen ? '' : 'container'}>
                <div className={isSmallScreen ? '' : 'col'}>

                    <form onSubmit={handleSubmit(onSubmit)} className={`edit-profile-wrapper ${isSmallScreen ? 'mobile' : ''}`}>

                        <Header page={'edit-profile'} context={[t.editProfile.editProfile]}></Header>

                        <div className="inputs-login">
                            {/* image */}
                            <div className='delete-user-container'>
                                <div className={`user-wrapper-edit pt-2 pb-2`}>
                                    {currentUser.profile || img ?
                                        <img src={img != '' ? img : currentUser.profile} className={`user-edit-profile base-image`}></img>
                                        :
                                        <div className={`user-edit-profile base-image`} style={{ backgroundColor: currentUser.color }}>
                                            <div className='profile-letter text-center'>
                                                {currentUser?.customerName?.charAt(0)?.toUpperCase()}
                                            </div>
                                        </div>}
                                    <img src={editProfile} type='button' className={`edit-profile-img ${currentLanguage == 'en-US' ? 'ltr' : ''}`} onClick={changeImage}></img>
                                    <input
                                        type="file"
                                        id="imageInput"
                                        ref={fileInputRef}
                                        onChange={handleFileChange}
                                        style={{ display: 'none' }}
                                    />
                                </div>
                                <button className="delete-acount-button"
                                    onClick={(e) => {
                                        e.preventDefault(); // Prevent form submission
                                        setShowDeleteModal(true);
                                    }}>
                                    {t.editProfile.deleteAcount}
                                </button>
                            </div>

                            <div className='bold-text'>{t.editProfile.personalInformation}</div>
                            {/* first and last name */}
                            <div className={isSmallScreen ? "container-email-password-mobile" : "container-email-password row"}>
                                {/* custumer name */}
                                <div className="form-group">
                                    <label htmlFor="customerName" className="label">
                                        {t.user.customerName}
                                    </label>
                                    <input
                                        id="customerName"
                                        className={`${isSmallScreen ? 'col-12' : 'col'} card-input pr-1 pl-1 ${errors.customerName ? 'is-invalid' : ''}`}
                                        {...register('customerName', {
                                            required: t.forms.requireField,
                                            minLength: { value: 1, message: t.forms.invalidCustomerName },
                                        })}
                                        defaultValue={currentUser?.customerName}
                                    />
                                    <div className="invalid-feedback">{errors.customerName && errors.customerName.message}</div>
                                </div>

                            </div>

                            {/* email and phone */}
                            <div className={isSmallScreen ? "container-email-password-mobile" : "container-email-password row"}>
                                {/* user email */}
                                <div className="form-group">
                                    <label htmlFor="userName" className="label">
                                        {t.user.email}
                                    </label>
                                    <input
                                        id="userName"
                                        className={`${isSmallScreen ? 'col-12' : 'col'} card-input pr-1 pl-1 ${errors.email ? 'is-invalid' : ''}`}
                                        {...register('email', {
                                            required: t.forms.requireField,
                                            pattern: {
                                                value: emailPattern,
                                                message: t.forms.invalidEmail,
                                            },
                                        })}
                                        defaultValue={currentUser.email}
                                    />
                                    <div className="invalid-feedback">{errors.email && errors.email.message}</div>
                                </div>

                                {/* user phone number */}
                                <div className="form-group">
                                    <label htmlFor="phone" className="label">
                                        {t.user.phone}
                                    </label>
                                    <input
                                        id="phone"
                                        className={`${isSmallScreen ? 'col-12' : 'col'} card-input pr-1 pl-1 ${errors.phone ? 'is-invalid' : ''}`}
                                        {...register('phone', {
                                            required: t.forms.requireField,
                                            pattern: {
                                                value: /^\d{7,}$/,
                                                message: t.forms.invalidPhone,
                                            },
                                        })}
                                        defaultValue={currentUser.phone}
                                    />
                                    <div className="invalid-feedback">{errors.phone && errors.phone.message}</div>
                                </div>

                            </div>

                            {/* reset password */}
                            <div className="row">
                                <div className='bold-text'>{t.editProfile.changePassword}</div>

                                {/* the current password */}
                                <div className={`col-6 form-group  ${isSmallScreen ? 'col-12' : `${currentLanguage == 'en-US' ? 'mr-3' : 'ml-2'}`}`}>
                                    <div htmlFor="currentPassword">
                                        {t.editProfile.currentPassword}
                                    </div>
                                    <div
                                        type='text'
                                        className={`card-input pr-1 pl-1 password  ${errors.currentPassword ? 'is-invalid' : ''}`}
                                    >
                                        <input
                                            id="currentPassword"
                                            type={'text'}
                                            className='password-input'
                                            {...register('currentPassword', {
                                                required: false,
                                                validate: (value) => {
                                                    if (value.length > 0 && isComparePasswordValid == false) {
                                                        return t.forms.invalidPassword;
                                                    }
                                                },
                                            })}
                                        />
                                    </div>
                                    {console.log('errors.currentPassword && errors.currentPassword.message', errors.currentPassword && errors.currentPassword.message)}

                                    <div className="invalid-feedback">{errors.currentPassword && errors.currentPassword.message}</div>
                                </div>

                                {/* the new password */}
                                <div className={`col-6 form-group  ${isSmallScreen ? 'col-12' : 'mt-2'}`}>
                                    <div htmlFor="newPassword">
                                        {t.user.newPassword}
                                    </div>
                                    <div
                                        type='text'
                                        className={`card-input pr-1 pl-1 password ${errors.newPassword ? 'is-invalid' : ''}`}
                                    >
                                        <input
                                            id="newPassword"
                                            type={showPassword ? 'text' : "password"}
                                            className='password-input'
                                            {...register('newPassword', {
                                                required: watch('currentPassword') ? t.forms.requireField : false,
                                                pattern: {
                                                    value: passwordPattern,
                                                    message: t.forms.invalidPassword,
                                                },
                                                minLength: { value: 8, message: t.forms.requireFieldCharacters },
                                            })}
                                        />
                                        <img className='password-img ml-3' type='button' src={showPassword ? hidePassword : showPasswordImg} onClick={() => setShowPassword(!showPassword)} />
                                    </div>

                                    <div className="invalid-feedback">{errors.newPassword && errors.newPassword.message}</div>
                                </div>

                                {/* the confirm password */}
                                <div className={`col-6 form-group  ${isSmallScreen ? 'col-12' : 'mt-2'}`}>
                                    <div htmlFor="validatePassword">
                                        {t.user.confirmPassword}
                                    </div>
                                    <div
                                        type='text'
                                        className={`card-input pr-1 pl-1 password  ${errors.validatePassword ? 'is-invalid' : ''}`}
                                    >
                                        <input
                                            id="validatePassword"
                                            className='password-input'
                                            type={showConfirmPassword ? 'text' : "password"}
                                            {...register('validatePassword', {
                                                required: ((watch('newPassword') && watch('currentPassword')) ? t.forms.requireField : false),
                                                validate: (value) => {
                                                    if (value !== watch('newPassword')) {
                                                        return 'הסיסמאות לא תואמות';
                                                    }
                                                },
                                                minLength: { value: 8, message: t.forms.requireFieldCharacters },
                                            })} />
                                        <img className='password-img ml-3' type='button' src={showConfirmPassword ? hidePassword : showPasswordImg} onClick={() => setShowConfirmPassword(!showConfirmPassword)} />
                                    </div>

                                    <div className="invalid-feedback">{errors.validatePassword && errors.validatePassword.message}</div>
                                </div>

                            </div>

                            {/* ok button    */}
                            <div className={`form-group text-center ${isSmallScreen ? '' : 'mt-2 mb-2'} `}>
                                <button type="submit" className="submit-button" disabled={isSubmitting}>
                                    {t.editProfile.saveData}
                                </button>
                            </div>

                        </div>

                    </form>

                    {/* are you sure modal */}
                    <Modal
                        show={showDeleteModal}
                        onHide={() => { setShowDeleteModal(false) }}
                        centered
                        className={`modal ${isSmallScreen ? "" : `right-margin ${currentLanguage == "en-US" ? "ltr" : ""}`}`}
                    >
                        <Modal.Body>
                            <div
                                type="button"
                                className={isSmallScreen ? "are-you-sure mobile" : "are-you-sure"}
                            >
                                <div className={`back-text ${isSmallScreen ? "mobile" : ""}`}>
                                    <div>{`האם אתה בטוח?`}</div>
                                    <div>{`יתרתך בסך ${currentUser?.balance} סריקות תמחק`}</div>
                                </div>
                            </div>
                            <div className={`frame-b ${isSmallScreen ? "mobile" : ""} ${currentLanguage == "en-US" ? "" : "reverse"}`}>
                                <div
                                    type="button"
                                    className={isSmallScreen ? "btn-ok mobile col-6 " : "btn-ok col-6"}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        deleteAcount();
                                    }}
                                >
                                    <div className="ok-button">
                                        <div>{t.editProfile.ok}</div>
                                    </div>
                                </div>
                                <div
                                    type="button"
                                    className={isSmallScreen ? "btn-cancel mobile col-6" : "btn-cancel col-6"}
                                    onClick={() => { setShowDeleteModal(false) }}
                                >
                                    <div className={isSmallScreen ? "btn-ok-text cancel mobile " : "btn-ok-text cancel"}>
                                        <div>{t.editProfile.cancel}</div>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>

                </div>
            </div >
        </>
    )

}