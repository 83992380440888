import produce from "immer";
import {
    UPDATE_TYPE, GET_USER_LOGIN, GET_USERS, SET_CURRENT_USER, LOGOUT, GET_CURRENT_USER,
    COMPARE_PASSWORD, CHANGE_LANGUAGE,
    UPDATE_DONT_SHOW_AGAIN,
    GET_ALL_USERS,
    UPDATE_USER,
    UPDATE_IS_SHOW_BROKEN_LETTER
} from "./types";

const currentUser = JSON.parse(localStorage.getItem('currentUser'));

const initialState = {
    currentUser: {},
    activeUsers: [],
    users:[],
    // language: currentUser?.language || 'he',
    language: currentUser?.language || localStorage.getItem('language') || 'he',
    comparePassword: false
};

export const userReducer = produce((initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_USER_LOGIN:
            if (payload) {
                localStorage.setItem('currentUser', JSON.stringify({ id: payload?.data?._id, time: new Date(), language: payload?.data?.language || 'he' }));
                initialState.currentUser = payload?.data;
                const usersIdList = JSON.parse(localStorage.getItem('usersIdList')) || [];
                if (!usersIdList.some(user => user.id === payload?.data?._id)) {
                    usersIdList.push({ id: payload?.data?._id, time: new Date(), language: payload?.data?.language || 'he' });
                }
                initialState.language = payload?.data?.language || 'he';
                localStorage.setItem('language', payload?.data?.language || 'he');
                localStorage.setItem('usersIdList', JSON.stringify(usersIdList));
            }
            return initialState;
        case GET_USERS:
            initialState.activeUsers = payload;
            return initialState;
        case GET_ALL_USERS:
            initialState.users = payload;
            return initialState;
        case GET_CURRENT_USER:
            initialState.currentUser = payload;
            return initialState;
        case COMPARE_PASSWORD:
            initialState.comparePassword = payload;
            return initialState;
        case SET_CURRENT_USER:
            const currentUser = JSON.parse(localStorage.getItem('currentUser'));
            localStorage.setItem('currentUser', JSON.stringify({ id: payload?._id, time: new Date(), language: currentUser?.language ? currentUser?.language : 'he' }));
            initialState.currentUser = payload;
            return initialState;
        case LOGOUT:
            const currentUserLogout = JSON.parse(localStorage.getItem('currentUser'));
            if (currentUserLogout?.id && currentUserLogout.id === payload) {
                localStorage.removeItem('currentUser');
                initialState.currentUser = {};
            }
            const activeUsersId = JSON.parse(localStorage.getItem('usersIdList'));
            const updatedListLogout = activeUsersId.filter((user) => user.id !== payload);
            localStorage.setItem('usersIdList', JSON.stringify(updatedListLogout));
            initialState.activeUsers = []
            return initialState;
        case UPDATE_TYPE:
            initialState.currentUser.typeDefultFolder = payload.data.typeDefultFolder;
            return initialState;
        case UPDATE_IS_SHOW_BROKEN_LETTER:
            initialState.currentUser.isAddBrokenLetter = payload.data.isAddBrokenLetter;
            return initialState;
        case UPDATE_USER:
            return initialState;
        case UPDATE_DONT_SHOW_AGAIN:
            initialState.currentUser.dontShowAgainScan = payload.data?.dontShowAgainScan;
            initialState.currentUser.dontShowAgainProject = payload.data?.dontShowAgainProject;
            initialState.currentUser.dontShowAgainUploadScan = payload.data?.dontShowAgainUploadScan;
            initialState.currentUser.dontShowAgainReportPage = payload.data?.dontShowAgainReportPage;
            initialState.currentUser.dontShowAgainFilterAlert = payload.data?.dontShowAgainFilterAlert;
            return initialState;
        case CHANGE_LANGUAGE:
            initialState.language = payload;
            const currentUserLanguage = JSON.parse(localStorage.getItem('currentUser'));
            localStorage.setItem('language', payload);
            localStorage.setItem('currentUser', JSON.stringify({ id: currentUserLanguage.id, time: new Date(), language: payload }));
            return initialState;
        default:
            return initialState;
    }
}, initialState)



