
import React, { useEffect } from 'react';
import emailjs from 'emailjs-com';
import { useNavigate } from 'react-router-dom';

export default function SendOpenningEmail({ id, email, userName }) {
    const navigate = useNavigate();
    const hash = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYzYTAxZjkwNWQxMTMxYjQ0NDFhZTdmOCIsImlhdCI6MTY3MTQzODIyNCwiZXhwIjoxNjcxNTI0NjI0fQ._8v30tb_0giuh4nXWC0sEhRtD4afurzrhnXI-x89vtg'

    async function sendEmail() {
        try {
            if (email) {
                const form = document.getElementById(`contact-form-${id}`);
                emailjs.sendForm('service_ztwcnnf',  'template_di7hi9fa', form, 'RG7No6OmeqiYqalp_')
                    .then(async (result) => {
                        localStorage.setItem(`email${id}`,email)
                        sessionStorage.removeItem('isNewUser')
                    }, (error) => {
                        console.log('error send email😅😅😅', email, error.text);
                    });
            }
            else {
                navigate('/register', { state: { email: email } })
            }
        }
        catch (error) {
            console.log('error🥹🥹🥹', error);
        }
    }

    useEffect(() => {
        const delay = 500;
        const timeoutId = setTimeout(() => {
            sendEmail();
        }, delay);

        return () => clearTimeout(timeoutId);
    }, []);


    return (
        <>
            <form id={`contact-form-${id}`} name="contact-form" onSubmit={sendEmail}>
                <input type="hidden" name="from_name" value={'StamScanner'} />
                <textarea hidden="hidden" name="to_email" id='toEmail' value={email} ></textarea>
                <textarea name='user_name' id='user_name' value={userName} hidden='hidden'></textarea>
                <p name="token" value={hash} hidden='hidden'></p>
            </form>
        </>
    );
}
