// import React, { useEffect } from 'react';
// import { useDispatch } from 'react-redux';
// import { logoutUser } from './store/action';
// import { useNavigate } from 'react-router-dom';

// const LogoutInactiveUser = () => {

//     const dispatch = useDispatch();
//     const navigate = useNavigate();
//     const currentUserId = JSON.parse(localStorage.getItem('currentUser'))?.id;

//     const logoutUserFunc = async () => {
//         try {
//             if (currentUserId) {
//                 console.log('Logging out user...');
//                 await dispatch(logoutUser(currentUserId));
//                 navigate("/login");
//             }
//         }
//         catch (error) {
//             console.log(error);
//         }
//     };

//     let timeoutId = setInterval(logoutUserFunc, 3600000);

//     const handleActivity = () => {
//         // console.log('Activity detected!', timeoutId);
//         clearInterval(timeoutId);
//         timeoutId = setInterval(logoutUserFunc, 3600000);
//     };

//     window.addEventListener('mousemove', handleActivity);
//     window.addEventListener('keydown', handleActivity);
//     window.addEventListener('keyup', handleActivity);
//     window.addEventListener('click', handleActivity);


//     return (
//         <></>
//     );
// };

// export default LogoutInactiveUser;
