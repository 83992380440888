import { combineReducers } from "redux";
import {projectReducer} from '../pages/projects/store/project-reducer'
import {scanReducer} from '../pages/scans/store/scan-reducer'
import {reportReducer} from '../pages/report/store/report-reducer'   
import {userReducer} from '../pages/user/store/user-reducer'
import {paymentReducer} from '../pages/payments/store/payment-reducer'

export default combineReducers({
    projectReducer,
    scanReducer,
    userReducer,
    reportReducer,
    paymentReducer
});