import React from 'react';
import { GoogleLogin } from 'react-google-login';
import { getUserGoogelLogin } from '../store/action';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const GoogleSignIn = () => {
    const clientId = '457529097789-6j638vdvdhj87i62je4md3tup9v66sku.apps.googleusercontent.com';
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const onSuccess = async (res) => {
        console.log('Login Success: currentUser:', res.profileObj);
        console.log('ID Token:', res.tokenId);
        // You can send the ID token to your server for verification
        const idToken = res.getAuthResponse().id_token;
        const resGoogleLogin = await dispatch(getUserGoogelLogin(idToken))
        if (resGoogleLogin) {
            console.log('resGoogleLogin', resGoogleLogin);
            if (resGoogleLogin?.isNewUser == true) {
                sessionStorage.setItem('isNewUser', resGoogleLogin.data._id)
            }
            navigate('/dashboard');
        }
        else {
            navigate('/register')
        }

    };

    const onFailure = (response) => {
        console.log('Login failed: res:', response);
    };

    return (
        <div>
            <GoogleLogin
                clientId={clientId}
                buttonText="Login with Google"
                onSuccess={onSuccess}
                onFailure={onFailure}
                cookiePolicy={'single_host_origin'}
            />
        </div>
    );
};

export default GoogleSignIn;
