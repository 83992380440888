import { useDispatch } from "react-redux";
import { createReport } from "../../report/store/action";
import { useState } from "react";
import './test.scss'
import Spinner from "../../../utils/spinner/spinner";
export default function TestCreateReport() {

    const dispatch = useDispatch();
    const [numberOfLoops, setNumberOfLoops] = useState(6);
    const [responsesStatus, setResponsesStatus] = useState([]);
    const [disabledCreateReport, setDisabledCreateReport] = useState(false);
    const [indexes, setIndexes] = useState([]);

    const runTest = async () => {
        setDisabledCreateReport(true)
        const requestBody = [
            {
                "_id": "66f12c8720e6c7f5dac73816",
                "userId": "6638d6ca529335435f23e53c",
                "name": "מזוזה 1",
                "relatedProject": "66f12c7120e6c7f5dac7380d",
                "type": "mezuza",
                "subType": "",
                "images": [
                    {
                        "_id": "66f12c8820e6c7f5dac73817",
                        "userId": "6638d6ca529335435f23e53c",
                        "name": "scan66f12c8720e6c7f5dac73816.jpg",
                        "containerName": "scans-images",
                        "type": "jpg",
                        "size": 424400,
                        "createdAt": "2024-09-23T08:53:28.749Z",
                        "__v": 0
                    }
                ],
                "createdAt": "2024-09-23T08:53:28.881Z",
                "updatedAt": "2024-09-23T08:53:28.881Z",
                "__v": 0
            },
            {
                "_id": "66f12c8920e6c7f5dac7381b",
                "userId": "6638d6ca529335435f23e53c",
                "name": "מזוזה 2",
                "relatedProject": "66f12c7120e6c7f5dac7380d",
                "type": "mezuza",
                "subType": "",
                "images": [
                    {
                        "_id": "66f12c8920e6c7f5dac7381c",
                        "userId": "6638d6ca529335435f23e53c",
                        "name": "scan66f12c8920e6c7f5dac7381b.jpg",
                        "containerName": "scans-images",
                        "type": "jpg",
                        "size": 218082,
                        "createdAt": "2024-09-23T08:53:29.281Z",
                        "__v": 0
                    }
                ],
                "createdAt": "2024-09-23T08:53:29.409Z",
                "updatedAt": "2024-09-23T08:53:29.409Z",
                "__v": 0
            },
            {
                "_id": "66f12c8920e6c7f5dac73820",
                "userId": "6638d6ca529335435f23e53c",
                "name": "מזוזה 3",
                "relatedProject": "66f12c7120e6c7f5dac7380d",
                "type": "mezuza",
                "subType": "",
                "images": [
                    {
                        "_id": "66f12c8920e6c7f5dac73821",
                        "userId": "6638d6ca529335435f23e53c",
                        "name": "scan66f12c8920e6c7f5dac73820.jpg",
                        "containerName": "scans-images",
                        "type": "jpg",
                        "size": 230691,
                        "createdAt": "2024-09-23T08:53:29.806Z",
                        "__v": 0
                    }
                ],
                "createdAt": "2024-09-23T08:53:29.936Z",
                "updatedAt": "2024-09-23T08:53:29.936Z",
                "__v": 0
            },
            {
                "_id": "66f12c8a20e6c7f5dac73825",
                "userId": "6638d6ca529335435f23e53c",
                "name": "מזוזה 4",
                "relatedProject": "66f12c7120e6c7f5dac7380d",
                "type": "mezuza",
                "subType": "",
                "images": [
                    {
                        "_id": "66f12c8a20e6c7f5dac73826",
                        "userId": "6638d6ca529335435f23e53c",
                        "name": "scan66f12c8a20e6c7f5dac73825.jpg",
                        "containerName": "scans-images",
                        "type": "jpg",
                        "size": 260563,
                        "createdAt": "2024-09-23T08:53:30.456Z",
                        "__v": 0
                    }
                ],
                "createdAt": "2024-09-23T08:53:30.585Z",
                "updatedAt": "2024-09-23T08:53:30.585Z",
                "__v": 0
            },
            {
                "_id": "66f12c8a20e6c7f5dac7382a",
                "userId": "6638d6ca529335435f23e53c",
                "name": "מזוזה 5",
                "relatedProject": "66f12c7120e6c7f5dac7380d",
                "type": "mezuza",
                "subType": "",
                "images": [
                    {
                        "_id": "66f12c8a20e6c7f5dac7382b",
                        "userId": "6638d6ca529335435f23e53c",
                        "name": "scan66f12c8a20e6c7f5dac7382a.jpg",
                        "containerName": "scans-images",
                        "type": "jpg",
                        "size": 202743,
                        "createdAt": "2024-09-23T08:53:30.980Z",
                        "__v": 0
                    }
                ],
                "createdAt": "2024-09-23T08:53:31.109Z",
                "updatedAt": "2024-09-23T08:53:31.109Z",
                "__v": 0
            },
            {
                "_id": "66f12c8a20e6c7f5dac7382a",
                "userId": "6638d6ca529335435f23e53c",
                "name": "מזוזה 5",
                "relatedProject": "66f12c7120e6c7f5dac7380d",
                "type": "mezuza",
                "subType": "",
                "images": [
                    {
                        "_id": "66f12c8a20e6c7f5dac7382b",
                        "userId": "6638d6ca529335435f23e53c",
                        "name": "scan66f12c8a20e6c7f5dac7382a.jpg",
                        "containerName": "scans-images",
                        "type": "jpg",
                        "size": 202743,
                        "createdAt": "2024-09-23T08:53:30.980Z",
                        "__v": 0
                    }
                ],
                "createdAt": "2024-09-23T08:53:31.109Z",
                "updatedAt": "2024-09-23T08:53:31.109Z",
                "__v": 0
            }
        ]

        const rotations = [0, 0, 0, 0, 0, 0]
        const responsesStatus = []

        await Promise.all(Array.from({ length: numberOfLoops }, async (_, index) => {
            try {
                requestBody.projectName = index;
                setIndexes(prevIndexes => [...prevIndexes, index]);
                const report = await createReportFunc(requestBody, rotations);
                responsesStatus.push(index);
                console.log(index + ' ' + report.status);
            } catch (error) {
                responsesStatus.push('failed');
            }
        }));
        setResponsesStatus(responsesStatus);
        setDisabledCreateReport(false);
        console.log('responsesStatus✨✨✨', responsesStatus);
        return responsesStatus;
    }

    const handleInputChange = (event) => {
        const value = parseInt(event.target.value);
        setNumberOfLoops(value);
    };

    const createReportFunc = async (ScanList, rotations) => {
        console.log("createReportFunc", ScanList);
        return new Promise(async (resolve, reject) => {
            try {
                const scansObjects = [];
                if (ScanList?.length > 0) {
                    ScanList.map((scan, index) => {
                        const obj = {
                            userId: '6638d6ca529335435f23e53c',
                            scanId: scan._id,
                            scanName: scan.name,
                            relatedProject:'66f146b9ae6a4af8194a34dc',// scan.relatedProject,
                            image: scan.images[0],
                            type: 'mezuza',
                            rotation: rotations[index],
                        };
                        scansObjects.push(obj);
                    });
                    console.log("scansObjects", scansObjects);
                    const report = await dispatch(
                        createReport({
                            scans: scansObjects,
                            scanType: 'mezuza',
                            locale: 'he',
                            userName: 'currentUser?.customerName',
                            projectName: 'currentProjectName',
                            projectId: '66f12c7120e6c7f5dac7380d',
                            userRoleAssiment: 'user',
                        })
                    );
                    console.log("node.js answer of upload report", report);
                    resolve(report);
                } else {
                    console.log("no allProjectUsersScans");
                    resolve(null);
                }
            } catch (error) {
                console.log(error);
                resolve(null);
            }
        });
    };

    return (
        <div className="test">
            number of loops (tabs):
            <input type="number"
                value={numberOfLoops}
                onChange={handleInputChange}
            ></input>

            <button className="btn btn-primary" onClick={runTest}>RUN</button>
            <div className="row row-container">
                indexes:{indexes && indexes.map(index => <div>{index}</div>)}
            </div>
            <div className="row row-container">
                responsesStatus:{responsesStatus && responsesStatus.map(index => <div>{index}</div>)}
            </div>

            {/* spinner on disabled */}
            {disabledCreateReport == true && (
                <Spinner value={100}></Spinner>
            )}
        </div>
    )
}