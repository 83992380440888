import { useDispatch, useSelector } from "react-redux"
import { getAllUsers, getCurrentUserById } from "../../user/store/action";
import { useEffect, useRef, useState } from "react";
import './user-management.scss'
import Header from "../../header/header";
import DataManagement from "../data-management/data-management";

export default function UserManagement() {
    const dispatch = useDispatch();
    const usersListStore = useSelector((store) => store.userReducer.users)
    const currentUserId = JSON.parse(localStorage.getItem('currentUser'))?.id;
    const currentUser = useSelector((store) => store.userReducer.currentUser);
    const isCurrentUserEffect = useRef(true)
    const isEditUser = currentUser && currentUser?.Role == 'super-admin' ? true : false;
    useEffect(() => {
        const fetchData = async () => {
            await dispatch(getAllUsers());
        };
        fetchData();
    }, []);

    useEffect(() => {
        if (currentUserId && isCurrentUserEffect.current) {
            dispatch(getCurrentUserById(currentUserId));
            isCurrentUserEffect.current = false;
        }
    }, [currentUserId, currentUser])

    const headerListSortAndFilter = [
        { label: 'שם משתמש', type: 'customerName', action: 'sort', subOptions: ['order', 'reverse'], index: 0 },
        { label: 'סוג משתמש', type: 'Role', action: 'filter', subOptions: ['user', 'premium', 'admin', 'super-admin', 'all'], index: 0 },
        { label: 'אימייל' },
        { label: 'טלפון', type: 'phone', action: 'filter', subOptions: ['phone', 'all'], index: 0 },
        { label: 'תאריך הצטרפות', type: 'createdAt', action: 'sort', subOptions: ['order', 'reverse'], index: 0 },
        { label: 'יתרה', type: 'balance', action: 'sort', subOptions: ['order', 'reverse'], index: 0 },
    ]

    return (
        <div>
            <Header page={'user-management'} context={['ניהול משתמשים']}></Header>
            <DataManagement
                dataType={'user'}
                dataList={usersListStore}
                header={`${usersListStore.length} משתמשים`}
                headerList={headerListSortAndFilter}
                isEditUser={isEditUser}
            ></DataManagement>
        </div>
    )
}